@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  html {
    font-family: "Inter", sans-serif;
  }

  ol {
    list-style-type: none;
  }
  ul {
    list-style-type: none;
  }

  input[type="number"]::-webkit-inner-spin-button,
  input[type="number"]::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  #tooltip {
    display: none;
    z-index: 20;
  }

  #tooltip[data-show] {
    display: block;
  }

  #tooltip[data-popper-placement^="top"] > #arrow {
    bottom: -4px;
  }

  #tooltip[data-popper-placement^="bottom"] > #arrow {
    top: -4px;
  }

  #tooltip[data-popper-placement^="left"] > #arrow {
    right: -4px;
  }

  #tooltip[data-popper-placement^="right"] > #arrow {
    left: -4px;
  }

  #arrow,
  #arrow::before {
    width: 8px;
    height: 8px;
    position: absolute;
    background: inherit;
    background-color: #101828;
  }

  #arrow {
    visibility: hidden;
  }

  #arrow::before {
    visibility: visible;
    content: "";
    transform: rotate(45deg);
  }
}

@layer components {
  input:-webkit-autofill {
    -webkit-text-fill-color: #171717;
    transition: background-color 5000s ease-in-out 0s;
    -webkit-box-shadow: 0 0 0px 1000px #fcfcfc inset;
  }

  select:-webkit-autofill {
    -webkit-text-fill-color: #171717;
    transition: background-color 5000s ease-in-out 0s;
    -webkit-box-shadow: 0 0 0px 1000px #fcfcfc inset;
  }

  textarea:-webkit-autofill {
    -webkit-text-fill-color: #171717;
    transition: background-color 5000s ease-in-out 0s;
    -webkit-box-shadow: 0 0 0px 1000px #fcfcfc inset;
  }

  @media (prefers-color-scheme: dark) {
    input:-webkit-autofill {
      -webkit-text-fill-color: #ededed;
      transition: background-color 5000s ease-in-out 0s;
      -webkit-box-shadow: 0 0 0px 1000px #161616 inset;
    }

    select:-webkit-autofill {
      -webkit-text-fill-color: #ededed;
      transition: background-color 5000s ease-in-out 0s;
      -webkit-box-shadow: 0 0 0px 1000px #161616 inset;
    }

    textarea:-webkit-autofill {
      -webkit-text-fill-color: #ededed;
      transition: background-color 5000s ease-in-out 0s;
      -webkit-box-shadow: 0 0 0px 1000px #161616 inset;
    }
  }
}

svg {
  max-width: 100%;
}

img {
  max-width: 100%;
}

input[type="password"]::-ms-reveal,
input[type="password"]::-ms-clear {
  display: none;
}

input[type="date"]::-webkit-inner-spin-button,
input[type="date"]::-webkit-calendar-picker-indicator {
  display: none;
  appearance: none;
}

.sidebar::-webkit-scrollbar {
  width: 12px;
}

.sidebar::-webkit-scrollbar-thumb {
  border-radius: 8px;
  background-color: #c5c6c9;
  background-clip: padding-box;
  border-left: 4px solid transparent;
  border-right: 4px solid transparent;
}

.hidden-scrollbar::-webkit-scrollbar {
  display: none;
}

input[type="date"]::-webkit-calendar-picker-indicator {
  background-origin: border-box;
  background-repeat: no-repeat;
  background-image: url("data:image/svg+xml;utf8, <svg xmlns='http://www.w3.org/2000/svg' width='16' height='16' viewBox='0 0 16 16' fill='none'> <path d='M15.36 1.44H12V0.16C12 0.072 11.928 0 11.84 0H10.72C10.632 0 10.56 0.072 10.56 0.16V1.44H5.44V0.16C5.44 0.072 5.368 0 5.28 0H4.16C4.072 0 4 0.072 4 0.16V1.44H0.64C0.286 1.44 0 1.726 0 2.08V15.36C0 15.714 0.286 16 0.64 16H15.36C15.714 16 16 15.714 16 15.36V2.08C16 1.726 15.714 1.44 15.36 1.44ZM14.56 14.56H1.44V6.96H14.56V14.56ZM1.44 5.6V2.88H4V3.84C4 3.928 4.072 4 4.16 4H5.28C5.368 4 5.44 3.928 5.44 3.84V2.88H10.56V3.84C10.56 3.928 10.632 4 10.72 4H11.84C11.928 4 12 3.928 12 3.84V2.88H14.56V5.6H1.44Z' fill='%23828282'/></svg>");
}

.slider-integration .slick-slide > div {
  margin: 0 2px;
}
.slider-integration .slick-list {
  margin: 0 -2px;
}
